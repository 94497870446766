import styled from 'styled-components';

const LayoutSidebarWrapper = styled.div`

    
.sidebar-form-wrapper {
    position: sticky;
    top: 20px;
}

.sidebar-form-wrapper .contact-form {
    border-bottom: 2px solid rgb(215, 144, 63);
    box-shadow: 0 0 10px 0 #dcdcdc;
    background-color: #fff;
    padding-bottom: 20px;
}
.sidebar-form-wrapper .contact-form input.form-input::placeholder,
.sidebar-form-wrapper .contact-form textarea.form-textarea::placeholder {
    font-size: 14px;
}
.sidebar-form-wrapper .contact-form .form-title,
.sidebar-form-wrapper .contact-form .form-inputs,
.sidebar-form-wrapper .contact-form .form-input,
.form-textarea,
.form-checkbox,
.form-checkbox-input,
.sidebar-form-wrapper .contact-form .form-checkbox-label,
.sidebar-form-wrapper .contact-form .form-submit {
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}
.sidebar-form-wrapper .contact-form .form-checkbox input.form-checkbox-input {
    margin-top: 5px;
}
.sidebar-form-wrapper .contact-form  select.form-input {
    height: 38px;
}
.sidebar-form-wrapper .contact-form textarea.form-textarea {
    height: 100px;
}
.sidebar-form-wrapper .contact-form .form-title {
    font-size: 20px;
    line-height: 32px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    background: rgb(215, 144, 63) none repeat scroll 0% 0%;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0px;
}

.sidebar-form-wrapper .contact-form .form-inputs {
    padding: 10px 20px;
    text-align: center;
}

.sidebar-form-wrapper .contact-form .form-input,
.form-textarea {
    margin-bottom: 10px;
    padding: 5px 10px;
    width: 100%;
    margin: 0px 0px 10px;
}

.form-textarea {
    resize: vertical;
}

.form-checkbox {
    align-items: flex-start;
    display: flex;
}

.form-checkbox-input {
    margin-right: 10px;
    width: 15px;
    margin-bottom: 10px;
    padding: 0px;
    margin: 0px 10px 10px 0px;
}

.sidebar-form-wrapper .contact-form .form-checkbox-label {
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 10px;
    text-align: left;
    color: rgb(78, 88, 101);
}

.sidebar-form-wrapper .contact-form .form-submit {
    background: rgb(232, 160, 74) none repeat scroll 0% 0%;
    border-radius: 4px;
    border: none;
    color: #000;
    padding: 10px 30px;
    cursor: pointer;
    appearance: button;
    text-transform: none;
    margin-top: 10px;
}

.sidebar-form-wrapper .contact-form .form-submit:hover {
    background: #ff900b;
    color: #fff;
}

.single-service-content-inner h2 {
    line-height: 36px;
}

@media only screen and (max-width: 1440px) {
    
}

@media only screen and (max-width: 800px) {

    .single-service-content-inner h2 {
    line-height: 36px;
}

    padding-left: 0;
    padding-right: 0;

    main.main-content.col {
        padding-left: 15px;
        padding-right: 15px;
    }

    aside.sidebar.col-md-322 {
        width: 100%;
        order: 2;
    }

}

`;

export default LayoutSidebarWrapper;