import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import LayoutInner from "common/components/Layout";
import Titlebar from "common/components/Titlebar";
import FAQToggle from "common/components/FAQs";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

const TapeInClipInonHairExtension = () => {

  const seoData = {
    title: 'Hair Extension in Delhi - Radiance Hair Studio',
    description: 'Queries about hair extensions? Grab 100% original Hair Extension in Delhi from Radiance Hair Studio. Our certified experts will guide you accordingly. Visit us.',
    keywords: ['Hair Extension'],
  };


  const faqs = [
    {
      title: "Will this damage my natural hair?",
      description:
        "In the case of tape-in, you must know that they are externally applied extensions. So, they have no role to play with your natural hair or scalp.",
    },
    {
      title: "Can I swim or sleep with these extensions?",
      description:
        "Tape-ins does have a lifespan, but that is not going to decrease with swimming or sleeping. The adhesive part remains at the scalp and they are not damaged before their life-span.",
    },
    {
      title: "What is the lifetime of Tape In extensions?",
      description:
        "The tape-ins that we provide from Radiance has usually 4-6 weeks’ life span.",
    },
    {
      title: "Why will I apply Tape In extension?",
      description:
        "You want your hair to look long, shiny and yet natural. This is the key reason to apply for these extensions. There are other methods too for the extension. However, those are painful and time-taking procedures too. The best way to get an instant and flexible looking long hair, tape-in is the best methods. So, apply those and feel the difference.",
    },
  ];
  const faqs2 = [
    {
      title: "Is the process painful?",
      description:
        "The literal answer to this question is – by No means. The extension application method of clip-in that we apply at Radiance is fully an external one and there is surgery into it. So, there is not even minute chance of pain in the entire process.",
    },
    {
      title: "Will I have to wear the extension all the time?",
      description:
        "Clip-in extensions are very much easy to be opened and re-worn. The important declaration here is that – you will not have to visit us at Radiance for the purpose of re-wearing your extension. So, open it up, when you feel and re-wear, as you need it.",
    },
    {
      title: "What is the life-span on Clip In extensions?",
      description:
        "As long as the clips remain intact – this retains with your usage style.However, we will guide you in the best way.        ",
    },
    {
      title: "Can I use shampoos?",
      description:
        "Yes, you can. The extensions are made of natural human hair and hence can be washed easily with shampoos.        ",
    },
  ];
  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />
        <Layout>
          <Titlebar title="Tape In & Clip In Hair Extensions" />
          <LayoutInner>
            <div className="service-cotentarea">
              <div class="single-service-content-inner">
                <img
                  class="service-image"
                  src="/home/Tape-In-&-Clip-In-Extension.png"
                />
                <h2>Tape In & Clip In Hair Extensions</h2>
                <p>
                <a href="/hair-extensions-for-men.php" style={{ color: "#373737"}}>Hair extensions</a> are applied on your head usually in three
                  styles – applying glues, using tape-ins and by using clip-ins.
                  Although the glues applied are medicated, still, some feel a
                  panic to apply the chemicals. In such cases, tape-ins and
                  clip-ins are the best options. In the case of tape-ins, both
                  side tapes are applied o your scalp and extensions are
                  attached to it.
                </p>
                <h3>Radiance Provide Advance Tape In & Clip In solutions!</h3>
                <p>
                  We at Radiance Hair Studio provide you with the best type of
                  tape-in hair extension that will last for 4 weeks. We use
                  natural human hair instead of synthetic hair so that you can
                  maintain your natural hair, which remains trimmed, and the
                  extensions easily with shampoos and other regular items. The
                  appearance that you will get in the process is fabulous.
                </p>
                <p>
                  Tape-ins at times does put some obligations to the clients.
                  Some tapes are such that they must not be wet or if made wet,
                  they must not be combed. Hence, clients often choose clip-ins
                  as the substitute. Clip-in extensions can be used as a cap as
                  they can be opened and re-worn anytime and for that, you need
                  not to visit any salon.
                </p>
                <p>
                <a href="/" style={{ color: "#373737"}}>Radiance</a> will give you a shiny and beautiful look with
                  clip-ins that will make you feel bold once again. We apply the
                  clips in such a way that you can easily attach and remove. We
                  ensure that the fact – you applied extended hair remains known
                  to you alone. So, none except you will be able to discover the
                  secret of your long hair, except you.
                </p>
                <h4>Benefits of Applying Tape-in Extensions</h4>

                <ul className="checkLists">
                  <li>
                    Tape-in is to be used over the head-scalp. So this is an
                    extension, where there is no need for any surgery. The full
                    process is easy to be applied and is absolutely pain-free.
                  </li>
                  <li>
                    You can change your styles easily while applying tape-ins.
                    However, you need to visit the Salon for the purpose.
                  </li>
                  <li>
                    The life span of tape-in extensions is nearly four to six
                    weeks. Hence, if you are not going to change your styles
                    fast, then they will serve you for more than a months.
                  </li>
                  <li>
                    Hair lengths of the extensions are perfect for a women's.
                    Those usually come up in different styles and formats. The
                    best among those will be guided to you by the professionals.
                  </li>
                </ul>

                <h4>Benefits of Applying Clip-in Extensions</h4>

                <ul className="checkLists">
                  <li>
                    Clip-ins is going to give you a lot of flexibility as you
                    can attach or detach them at your wish anytime at your home.
                  </li>
                  <li>
                    You can maintain those for a long time by using shampoos or
                    serums if you are provided an extension cap, which is made
                    of human hair.
                  </li>
                  <li>
                    Longevity of the caps is long enough and you can wear those,
                    either with your trimmed hair or by applying clips at your
                    bald part
                  </li>
                  <li>
                    If you are experiencing semi-permanent hair loss and want to
                    apply for the clip-in extensions at those affected areas,
                    you will have such option too in this case.
                  </li>
                </ul>
              </div>
              <div class="faq-description">
                <h3>FAQs about the Tap-in Extension.</h3>

                <FAQToggle faqs={faqs} />
              </div>
              <div class="faq-description">
                <h3>FAQs about the Clip-in Extension.</h3>

                <FAQToggle faqs={faqs2} />
              </div>
            </div>
          </LayoutInner>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default TapeInClipInonHairExtension;
