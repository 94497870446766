import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus  } from '@fortawesome/free-solid-svg-icons';
import { faMinus  } from '@fortawesome/free-solid-svg-icons';

import FAQstyles from './faqs.style';

function FAQs({ title, description, isOpen, toggleFAQ }) {
  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={toggleFAQ}>
      <div className='titlewap'>
        <h3 className="faq-title">{title}</h3>
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
      </div>
      {isOpen && <p className="faq-description">{description}</p>}
    </div>
  );
}

function FAQToggle({ faqs }) {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    setOpenIndex(index === openIndex ? -1 : index);
  };

  return (
    <FAQstyles>
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <FAQs
          key={index}
          {...faq}
          isOpen={index === openIndex}
          toggleFAQ={() => toggleFAQ(index)}
        />
      ))}
    </div>
    </FAQstyles>
  );
}

export default FAQToggle;