import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const Sidebar = () => {
  return (
    <aside className="sidebar col-md-322">
      <div className="single-service-sidebar">
        <div className="services-list">
          <h3>Hair Solutions for Men</h3>
          <ul>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/wigs-for-men.php"
                className=""
              >
                Wigs for Men
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-patch-mens.php"
                className=""
              >
                Hair Patch for Men
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-replacement-for-men.php"
                className=""
              >
                Hair Replacement
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/non-surgical-hair-replacement-for-men.php"
                className=""
              >
                Non-Surgical Hair Replacement
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-extensions-for-men.php"
                className=""
              >
                Human Hair Extension
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/customized-wigs-for-men.php"
                className=""
              >
                Customized Wigs
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-bonding-for-men.php"
                className=""
              >
                Hair Bonding
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-weaving-for-mens.php"
                className=""
              >
                Hair Weaving
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/tape-in-and-clip-in-hair-extension.php"
                className=""
              >
                Tape In & Clip In on Hair Extensions
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/wigs-for-men-cancer-patients.php"
                className=""
              >
                Wigs for Cancer Patients
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-fixing.php"
                className=""
              >
                Hair Fixing
              </Link>
            </li>
          </ul>
        </div>

        
          <div className="services-list">
          <h3>Hair Solutions for Women</h3>
          <ul>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/customized-wigs-for-women.php"
                className=""
              >
                Customized Wigs for Women
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-replacement-for-women.php"
                className=""
              >
                Hair replacement for women
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/non-surgical-hair-replacement.php"
                className=""
              >
                Non surgical hair replacement
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/volumizer-hair-additions-for-women.php"
                className=""
              >
                Volumizer hair additions for women
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-extention-for-womens.php"
                className=""
              >
               Hair extention for womens
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-bonding-for-women.php"
                className=""
              >
                Hair bonding for women
              </Link>
            </li>
           
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/hair-weaving-for-women.php"
                className=""
              >
               Hair weaving for women
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/tape-in-and-clip-in-hair-extension.php"
                className=""
              >
                Tape In & Clip In on Hair Extensions
              </Link>
            </li>
            <li itemprop="name" class="pt-2 pb-2">
              <Link
                itemprop="url"
                activeClassName="active"
                to="/womens-wigs-for-cancer-patients.php"
                className=""
              >
                Wigs for Cancer Patients
              </Link>
            </li>
            
          </ul>
        </div>


      

        <div className="sidebar-form-wrapper">
          <form className="contact-form" style={{display: 'none'}}>
            <h3 className="form-title">Book An Appointment</h3>
            <div className="form-inputs">
              <input
                type="text"
                name="name"
                value=""
                placeholder="Enter your name"
                className="form-input"
              />
              <input
                type="email"
                name="email"
                value=""
                placeholder="Enter your email"
                className="form-input"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone number"
                value=""
                className="form-input"
              />
              <select className="form-input">
                <option value="delhi">Select city</option>
                <option value="delhi">Delhi</option>
                <option value="mumbai">Mumbai</option>
                <option value="bangalore">Bangalore</option>
                <option value="chennai">Chennai</option>
                <option value="kolkata">Kolkata</option>
                <option value="hyderabad">Hyderabad</option>
                <option value="pune">Pune</option>
                <option value="ahmedabad">Ahmedabad</option>
              </select>
              <textarea
                placeholder="Kindly share a brief of your problem"
                name="message"
                rows="2"
                data-validation-optional="true"
                className="form-textarea"
              ></textarea>
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  name="bookConsultation"
                  value="check"
                  className="form-checkbox-input"
                />
                <label for="bookConsultation" className="form-checkbox-label">
                  Book my Consultation & Contact me over call, email, SMS,
                  WhatsApp to get me more information!
                </label>
              </div>
              <button type="submit" className="form-submit">
                Submit
              </button>
            </div>
          </form>

          <div className="contactinfo4">
            <div className="contactinfoo">
              <img src="/help-image-1.png" alt="contact us" />
              <h4>
                Need Help? <br />
                Talk with Expert
              </h4>
              <p>Call Anytime</p>
              <a href="tel:+919958585649">+91-99585-85649</a>
            </div>
          </div>

        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
