import React from 'react';
import Sidebar from 'containers/Interior/Sidebar';
import LayoutSidebarWrapper from './layout.style';

const Layout = ({ children }) => {
  return (
    <LayoutSidebarWrapper className="container sidebarLayout row m-auto" >
        <Sidebar />
        <main className="main-content col">{children}</main>
    </LayoutSidebarWrapper>
  );
};

export default Layout;
